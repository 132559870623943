// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/motion.ts"
);
import.meta.hot.lastModified = "1729211991241.1235";
}
// REMIX HMR END

import { Variants } from 'framer-motion';

export const defaultEasing = [0.6, -0.05, 0.01, 0.99];

export const staggerHalf = {
  animate: { transition: { staggerChildren: 0.05 } },
};

export const staggerOne = {
  animate: { transition: { staggerChildren: 0.1 } },
};

export const modalVariants = {
  hidden: {
    opacity: 0,
    top: '100%',
    transition: { duration: 0.8, ease: defaultEasing },
  },
  visible: {
    opacity: 1,
    top: '50%',
    transition: { duration: 0.8, ease: defaultEasing },
  },
};

export const modalOverlayVariants = {
  hidden: { opacity: 0, transition: { duration: 0.2, delay: 0.2 } },
  visible: { opacity: 1, transition: { duration: 0.2 } },
};

export const modalFadeInUpVariants = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.8, ease: defaultEasing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.8, ease: defaultEasing },
  },
};

export const authFadeInUpVariants = {
  initial: {
    y: 30,
    opacity: 0,
    transition: { duration: 0.8, ease: defaultEasing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.8, ease: defaultEasing },
  },
};

export const navbarFadeInVariants = {
  hidden: { opacity: 0, transition: { duration: 0.2 } },
  visible: { opacity: 1, transition: { duration: 0.2 } },
};

export const posterFadeInVariants = {
  initial: {
    y: 20,
    opacity: 0,
    transition: { duration: 0.5, ease: defaultEasing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5, ease: defaultEasing },
  },
  exit: {
    y: 20,
    opacity: 0,
    transition: { duration: 0.5, ease: defaultEasing },
  },
};

export const bannerFadeInVariants = {
  initial: {
    opacity: 0,
    transition: { duration: 0.8, ease: defaultEasing },
    willChange: 'opacity, transform',
  },
  animate: {
    opacity: 1,
    transition: { duration: 0.8, ease: defaultEasing },
    willChange: 'opacity, transform',
  },
  exit: {
    opacity: 0,
    transition: { delay: 0.4, duration: 0.8, ease: defaultEasing },
    willChange: 'opacity, transform',
  },
};

export const bannerFadeInLoadSectionVariants = {
  initial: { opacity: 0, transition: { duration: 0.4, ease: defaultEasing } },
  animate: { opacity: 1, transition: { duration: 0.4, ease: defaultEasing } },
  exit: { opacity: 0, transition: { duration: 0.4, ease: defaultEasing } },
};

export const bannerFadeInUpVariants = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.8, ease: defaultEasing },
    willChange: 'opacity, transform',
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { delayChildren: 0.4, duration: 0.8, ease: defaultEasing },
    willChange: 'opacity, transform',
  },
  exit: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.8, ease: defaultEasing },
    willChange: 'opacity, transform',
  },
};

export const authPageFadeInVariants = {
  initial: { opacity: 0, transition: { duration: 0.6, ease: defaultEasing } },
  animate: { opacity: 1, transition: { duration: 0.6, ease: defaultEasing } },
  exit: { opacity: 0, transition: { duration: 0.6, ease: defaultEasing } },
};

export const creditsFadeInUpVariants = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.8, ease: defaultEasing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.8, duration: 0.8, ease: defaultEasing },
  },
  exit: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.8, ease: defaultEasing },
  },
};

export const defaultPageFadeInVariants = {
  initial: {
    opacity: 0,
    transition: { duration: 0.6, ease: defaultEasing },
    willChange: 'opacity, transform',
  },
  animate: {
    opacity: 1,
    transition: { duration: 0.6, ease: defaultEasing },
    willChange: 'opacity, transform',
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.6, ease: defaultEasing },
    willChange: 'opacity, transform',
  },
};

export const featureCardAnimateIn = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.8, ease: defaultEasing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.8, duration: 0.8, ease: defaultEasing },
  },
  exit: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.8, ease: defaultEasing },
  },
};

export const verticalCardAnimateIn = {
  initial: {
    x: 60,
    opacity: 0,
    transition: { duration: 0.8 },
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: { delay: 0.8, duration: 0.8 },
  },
  exit: {
    x: 60,
    opacity: 0,
    transition: { duration: 0.8 },
  },
};

// Scale variants
export const scaleInVariants = {
  initial: { scale: 0.9, opacity: 0 },
  animate: {
    scale: 1,
    opacity: 1,
    transition: { duration: 1, ease: defaultEasing },
  },
};

export const scaleOutVariants = {
  initial: { scale: 1, opacity: 1 },
  exit: {
    scale: 0.9,
    opacity: 0,
    transition: { duration: 0.3, ease: defaultEasing },
  },
};

// Slide variants
export const slideInBlurredFromLeftAndFadeInVariants: Variants = {
  initial: { y: '100%', opacity: 0, filter: 'blur(10px)' },
  animate: {
    x: 0,
    opacity: 1,
    filter: 'blur(0px)',
    transition: { duration: 2, ease: defaultEasing },
  },
};

export const slideInFromLeftVariants = {
  initial: { x: '-100%', opacity: 0 },
  animate: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.6, ease: defaultEasing },
  },
};

export const slideInFromRightVariants = {
  initial: { x: '100%', opacity: 0 },
  animate: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.6, ease: defaultEasing },
  },
  exit: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.6, ease: defaultEasing },
  },
};

// Rotate variants
export const rotateInVariants = {
  initial: { rotate: -20, opacity: 0 },
  animate: {
    rotate: 0,
    opacity: 1,
    transition: { duration: 0.8, ease: defaultEasing },
  },
};

// Stagger variants (for animating multiple children)
export const staggerContainerVariants = {
  animate: {
    transition: {
      staggerChildren: 0.2, // Adjust delay between children as needed
    },
  },
};

// Other variants (customize as needed)
export const fadeInVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 1, ease: defaultEasing } },
};

export const fadeOutVariants = {
  initial: { opacity: 1 },
  exit: { opacity: 0, transition: { duration: 1, ease: defaultEasing } },
};

export const staggeredFadeInAndScaleUpVariants = {
  initial: { opacity: 0, scale: 0.8 },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: defaultEasing,
      staggerChildren: 0.1, // Delay between children
    },
  },
};

export const fadeInAndScaleUpVariants = {
  initial: { opacity: 0, scale: 0.8 },
  animate: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.5, ease: defaultEasing },
  },
};

export const rotateInAndFadeInVariants = {
  initial: { rotate: -360, opacity: 0 },
  animate: {
    rotate: 0,
    opacity: 1,
    transition: { duration: 0.8, ease: defaultEasing, delay: 0.2 }, // Slight delay for the fade in
  },
};

export const slideInFromLeftAndFadeInVariants = {
  initial: { x: '-100%', opacity: 0 },
  animate: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.6, ease: defaultEasing },
  },
};
