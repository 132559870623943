// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/click-helpers.ts"
);
import.meta.hot.lastModified = "1729211991241.1235";
}
// REMIX HMR END

export const handleScrollHelper = (
  event: React.MouseEvent<HTMLAnchorElement | HTMLLabelElement, MouseEvent>,
  id: string,
  callback?: () => void
) => {
  console.log("handleScrollHelper");
  
  event.preventDefault();
  callback?.();
  const element = document.getElementById(id);
  if (element) {
    const yOffset = -85;
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  }
};

export const handleNavClickHelper = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  target: string,
  callback?: (target: string) => void
) => {
  event.preventDefault();
  callback?.(target);
};
